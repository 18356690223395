import { useEffect, useState } from "react";
import "./App.css";
import Login from "./components/Login/Login";
import PasswordRecovery from "./components/PasswordRecovery/PasswordRecovery";
import Api from "./data/api";

function App() {
  const [isPT, setIsPT] = useState(null);

  useEffect(() => {
    setIsPT(window.navigator.language === "pt-PT");
  }, []);

  return <div>{isPT !== null && (Api.getQueryVariable("mode") === "PasswordRecovery" ? <PasswordRecovery isPT={isPT}/> : <Login isPT={isPT}/>) }</div>;
}

export default App;
