export default class Api {
  // define headers for api call - used in getData function
  static getHeaders = () => {
    return new Headers({
      "Content-Type": "application/json",
      Accept: "application/json",
    });
  };
  // define base url for api call - used in getData function
  static getBaseUrl = () => {
    let baseUrl = "";
    if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
      // dev code
      baseUrl = "https://dev.hostpms.com/Apiv2/";
    } else {
      baseUrl = "/Apiv2/";
    }

    return baseUrl;
  };

  // getData from database - connection to database - method GET
  static getData = (url, data) => {
    return new Promise((resolve, reject) => {
      let params = "";

      if (typeof data == "object") {
        params = Object.keys(data)
          .map((k) =>
            Array.isArray(data[k])
              ? data[k].map((kk) => encodeURI(`${k}=${kk}`)).join("&")
              : encodeURI(`${k}=${data[k]}`)
          )
          .join("&");
      } else if (typeof data == "string") params = data;

      // console.log(params);
      if (params !== "") url += url.indexOf("?") > 0 ? params : "?" + params;

      let headers = Api.getHeaders();

      fetch(`${Api.getBaseUrl()}${url}`, {
        method: "GET",
        headers: headers,
      })
        .then((res) => {
          //Check here if result is ok
          if (!res.ok) throw res;

          return res.json();
        })
        .then(
          (result) => {
            resolve(result);
          },
          // Note: it's important to handle errors here
          // instead of a catch() block so that we don't swallow
          // exceptions from actual bugs in components.
          (err) => {
            if (err.status === 401) {
              Api.gotToLogin();
            }
            if (err.text) {
              err.text().then((errorMessage) => {
                let e = Api.extractError(errorMessage);
                if (e.isJson && e.data.errorCode === 2) {
                  Api.gotToLogin();
                } else {
                  reject(err.text);
                }
              });
            } else {
              reject(err);
            }
          }
        );
    });
  };

  static postData = (url, data) => {
    return new Promise((resolve, reject) => {
      let headers = Api.getHeaders();
      let baseUrl = "";
      if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
        // dev code
        baseUrl = "https://dev.hostpms.com/api/v1/";
      } else {
        baseUrl = "/api/v1/";
      }

      fetch(`${baseUrl}${url}`, {
        crossDomain: true,
        mode: "cors",
        method: "POST",
        cache: "no-cache",
        credentials: "same-origin",
        headers: headers,
        body: JSON.stringify(data),
      })
        .then((res) => {
          //If response is not ok a error must be thrown
          if (!res.ok) throw res;

          return res.json();
        })
        .then(
          (result) => {
            resolve(result);
          },
          // Note: it's important to handle errors here instead of a catch() block so that we don't swallow exceptions from actual bugs in components.
          (err) => {
            if (err.status === 401) {
              //401: Unauthorized goes to login
              Api.gotToLogin();
            } else if (err.text) {
              err.text().then((errorMessage) => {
                let e = Api.extractError(errorMessage);
                if (e.isJson) {
                  if (e.authenticationError) {
                    Api.gotToLogin();
                  } else {
                  }
                } else {
                  reject(err.text);
                }
              });
            } else {
              reject(err);
            }
          }
        );
    });
  };

  // extract getData errors
  static extractError(text) {
    try {
      let res = JSON.parse(text);
      return {
        isJson: true,
        data: res,
      };
    } catch (e) {
      return {
        isJson: false,
        data: text,
      };
    }
  }

  /* post method to login into the app */
  static loginAuth = (connection, user, pass) => {
    return new Promise((resolve, reject) => {
      let headers = { Referer: `${window.location.origin}/Login` };

      let data = new URLSearchParams();
      data.append("ConnectionName", connection);
      data.append("UserName", user);
      data.append("Password", pass);

      fetch(`${window.location.origin}/Account/Login`, {
        crossDomain: true,
        mode: "cors",
        method: "POST",
        cache: "no-cache",
        credentials: "same-origin",
        headers: headers,
        body: data,
      })
        .then((res) => {
          //Check here if result is ok
          if (!res.ok) throw res;

          return res.json();
        })
        .then(
          (result, error) => {
            resolve(result);
            console.log("result", result);
          },
          // Note: it's important to handle errors here
          // instead of a catch() block so that we don't swallow
          // exceptions from actual bugs in components.
          (err, error) => {
            reject(err);
          }
        );
    });
  };

  /* get method only need to pass url and connection name to headers */
  static testConnection = (url, connection) => {
    return new Promise((resolve, reject) => {
      let headers = { "x-connectionname": `${connection}` };

      fetch(`${Api.getBaseUrl()}${url}`, {
        method: "GET",
        headers: headers,
      })
        .then((res) => {
          //Check here if result is ok
          if (!res.ok) throw res;

          return res.json();
        })
        .then(
          (result, error) => {
            resolve(result);
          },
          // Note: it's important to handle errors here
          // instead of a catch() block so that we don't swallow
          // exceptions from actual bugs in components.
          (err, error) => {
            reject(err);
          }
        );
    });
  };

  static getHeadersV3 = () => {
    return {
      "Content-Type": "application/json",
      Accept: "application/json",
    };
  };


  static getBaseUrlV3 = () => {
    let baseUrl = "";
    if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
      // dev code
      baseUrl = "https://dev.hostpms.com/Apiv3/";
    } else {
      baseUrl = "/Apiv3/";
    }

    return baseUrl;
  };

  
  static postDataV3 = (url, data) => {
    return new Promise((resolve, reject) => {
      let headers = Api.getHeadersV3();

      fetch(`${Api.getBaseUrlV3()}${url}`, {
        crossDomain: true,
        mode: "cors",
        method: "POST",
        cache: "no-cache",
        credentials: "same-origin",
        headers: headers,
        body: JSON.stringify(data),
      })
        .then((res) => {
          //If response is not ok a error must be thrown
          if (!res.ok) throw res;

          return res.json();
        })
        .then(
          (result) => {
            resolve(result);
          },
          // Note: it's important to handle errors here instead of a catch() block so that we don't swallow exceptions from actual bugs in components.
          (err) => {
            if (err.status === 401) {
              //401: Unauthorized goes to login
              Api.gotToLogin();
            } else if (err.text) {
              err.text().then((errorMessage) => {
                let e = Api.extractError(errorMessage);
                if (e.isJson) {
                  if (e.authenticationError) {
                    Api.gotToLogin();
                  } else {
                  }
                } else {
                  reject(err.text);
                }
              });
            } else {
              reject(err);
            }
          }
        );
    });
  };

  // redirect to login when connection to database fails
  static gotToLogin() {
    /* disable because on dev enters in loop */
    /*window.location.href = `${window.location.origin}/Account/Login?ReturnUrl=${encodeURIComponent(window.location.pathname)}`;*/
  }

  // read query variable
  /* example: Api.getQueryVariable("standalone") === "true"; */
  static getQueryVariable = (paramName) => {
    let vars = window.location.search.substring(1).split("&");

    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split("=");
      if (
        decodeURIComponent(pair[0]).toLowerCase() === paramName.toLowerCase()
      ) {
        return decodeURIComponent(pair[1]);
      }
    }
    return "";
  };
}
