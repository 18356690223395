export function	IsNullOrUndefined(value) {
    

    if(value !== null && value !== undefined){
        if(Array.isArray(value) && value.length === 0)
        {
            return true; 
        }
        else if(Object.keys(value).length === 0)
        {
            return true;
        }
        else{
            return false;
        }
    }
    else
    {
        return true;
    }
}
