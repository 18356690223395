import React, { useEffect, useState } from "react";

import "./Login.css";
import Api from "./../../data/api";

import logoHost from "./../../media/logo_host.png";
import logos from "./../../media/host_logos.png";

import { IsNullOrUndefined } from "../../helpers/HelperFunctions";

export default function Login({ isPT }) {
  const [connections, setConnections] = useState(null);
  const [selectedConnection, setSelectedConnection] = useState(null);
  const [selectedUser, setSelectedUser] = useState("");
  const [selectedPassword, setSelectedPassword] = useState("");
  const [showMessage, setShowMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [testConnectionResult, setTestConnectionResult] = useState(null);
  const [enDate, setEnDate] = useState(null);
  const [ptDate, setPtDate] = useState(null);
  const [currentDate, setCurrentDate] = useState(null);
  const [loadingTest, setLoadingTest] = useState(false);
  const [errorTest, setErrorTest] = useState(false);
  const [loadingOnly, setLoadingOnly] = useState(false);
  const [recoveryMode, setRecoveryMode] = useState(false);
  const [selectedRecoveryEmail, setSelectedRecoveryEmail] = useState("");
  const [alertFillUsernameForRecover, setAlertFillUsernameForRecover] =
    useState(false);
  const [sucessSendRecovery, setSucessSendRecovery] = useState(null);
  const [emailRecoverError, setEmailRecoverError] = useState(null);

  useEffect(() => {
    if (connections === null) {
      getConnectionList();
      var today = new Date();
      let month = today.getMonth() + 1;
      let day = today.getDate();

      console.log(month, month < 10, day);
      var date =
        today.getFullYear() +
        "-" +
        (month < 10 ? `0${month}` : month) +
        "-" +
        (day < 10 ? `0${day}` : day);
      setCurrentDate(date);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (connections !== null) {
      setSelectedConnection(
        localStorage.lastConnectionV10Dashboard !== undefined
          ? localStorage.lastConnectionV10Dashboard
          : connections[0].ConnectionName
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connections]);

  useEffect(() => {
    if (selectedConnection !== null) {
      testConnection();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedConnection]);

  function testConnection() {
    setLoadingTest(true);
    Api.testConnection(`System/TestConnection`, selectedConnection)
      .then((result) => {
        setTestConnectionResult(result);
        var options = {
          weekday: "short",
          year: "numeric",
          month: "long",
          day: "numeric",
        };
        var today = new Date(result.HotelDateFixedFormat);

        setEnDate(
          today.toLocaleDateString("en-US", options).charAt(0).toUpperCase() +
            today.toLocaleDateString("en-US", options).replace(".", "").slice(1)
        );
        setPtDate(
          today
            .toLocaleDateString("pt-BR", options)
            .replace(".", "")
            .charAt(0)
            .toUpperCase() +
            today.toLocaleDateString("pt-BR", options).replace(".", "").slice(1)
        );
        setLoadingTest(false);
        setErrorTest(false);
      })
      .catch((error) => {
        setErrorTest(true);
        setLoadingTest(false);
        setTestConnectionResult("erro");
      });
  }

  function getConnectionList() {
    Api.getData(`System/ConnectionList`)
      .then((result) => {
        setConnections(result);

        if (localStorage.getItem("lastConnectionV10Dashboard") === undefined) {
          localStorage.setItem(
            "lastConnectionV10Dashboard",
            result[0].ConnectionName
          );
        }
      })
      .catch((error) => {
        /*  setErrors(true); */
      });
  }

  function onFinish(event) {
    setLoading(true);
    setShowMessage(true);
    event.preventDefault();
    if (selectedUser.length > 0 && selectedPassword.length > 0) {
      setShowMessage(false);
      setLoading(true);
      Api.loginAuth(selectedConnection, selectedUser, selectedPassword)
        .then((result) => {
          console.log("loginAuth", result);
          if (result.success === true) {
            if (result.RedirectToUri !== null && result.RedirectToUri !== "") {
              window.location = result.RedirectToUri;
              return;
            }

            if (result.mustChangePassword) {
              window.location.href = `${window.location.origin}/Login/?mode=PasswordRecovery&ConnectionName=${selectedConnection}&user=${selectedUser}&token=${result.passwordChangeToken}`;
            } else {
              let targetUrl = Api.getQueryVariable("ReturnUrl");
              window.location.href =
                targetUrl !== "" ? targetUrl : `${window.location.origin}`;
              setLoadingOnly(true);
            }
          } else {
            setShowMessage(true);
            setErrorMessage(result.message);
            setSelectedPassword("");
            const input = document.getElementById("pw");
            input.focus();
          }
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
          setShowMessage(true);
          const input = document.getElementById("pw");
          input.focus();
          setSelectedPassword("");
        });

      Api.postDataV3("api/Authenticate", {
        provider: "credentials",
        UserName: `${selectedConnection}|${selectedUser}`,
        Password: selectedPassword,
        RememberMe: true,
      }).then(
        (result) => {
          console.log("auth", result);
        },
        (err) => {
          console.log(err);
        }
      );
    } else {
      setShowMessage(true);
      setSelectedUser("");
      setSelectedPassword("");
    }
  }

  function handleChangeConnection(e) {
    setSelectedConnection(e.target.value);
    localStorage.setItem("lastConnectionV10Dashboard", e.target.value);
  }

  function handleChangeName(e) {
    setSelectedUser(e.target.value);
  }

  function handleChangeRecoveryEmail(e) {
    setSelectedRecoveryEmail(e.target.value);
  }

  function handleChangePassword(e) {
    setSelectedPassword(e.target.value);
  }

  function sendEmailForRecovery() {
    Api.postData(`/account/PasswordRecoveryCreateToken`, {
      connectionName: selectedConnection,
      userCode: selectedUser,
      email: selectedRecoveryEmail,
    })
      .then((result) => {
        if (result.success) {
          setEmailRecoverError(null);
          setSucessSendRecovery(result.message);
        } else {
          setSucessSendRecovery(null);
          setEmailRecoverError(result.message);
        }
      })
      .catch((error) => {});
  }
  /* ${window.location.origin} */
  return (
    <>
      {testConnectionResult !== null && (
        <div
          className="login-wrapper"
          style={{
            backgroundImage: IsNullOrUndefined(
              testConnectionResult.WallpaperImage
            )
              ? `url("images/login_background.png")`
              : `url(${window.location.origin}${testConnectionResult.WallpaperImage}?conn=${localStorage.lastConnectionV10Dashboard})`,
          }}
        >
          <div className="login-layout">
            {testConnectionResult !== null ? (
              <img
                className="img-hotel-logo"
                alt="img-hotel-logo"
                src={
                  testConnectionResult.HotelImage !== undefined
                    ? `${window.location.origin}${testConnectionResult.HotelImage}?w=200&conn=${localStorage.lastConnectionV10Dashboard}`
                    : "images/logo_hotel.png"
                }
              ></img>
            ) : (
              <img
                className="loading-gif"
                alt="loading-gif"
                src="images/spinning_loading.gif"
              ></img>
            )}
            {loading !== true && connections !== null ? (
              <>
                {!loadingOnly ? (
                  <form className="login-form" onSubmit={onFinish}>
                    <label>
                      {isPT ? "Propriedade" : "Property"}
                      <div className="select-box">
                        {selectedConnection !== null && (
                          <select
                            disabled={recoveryMode}
                            value={selectedConnection.toUpperCase()}
                            onChange={handleChangeConnection}
                          >
                            {connections.map((c, index) => {
                              return (
                                <option
                                  key={index}
                                  value={c.ConnectionName.toUpperCase()}
                                >
                                  {c.Description}
                                </option>
                              );
                            })}
                          </select>
                        )}
                      </div>
                    </label>
                    <label>
                      {isPT ? "Utilizador" : "Username"}
                      <input
                        autoFocus
                        placeholder={
                          isPT
                            ? "Introduza o seu utilizador"
                            : "Type your username"
                        }
                        type="text"
                        value={selectedUser}
                        onChange={handleChangeName}
                      />
                    </label>
                    {showMessage &&
                      (selectedUser.length > 0 ? (
                        ""
                      ) : (
                        <span className="login-form-fields-error">
                          {isPT
                            ? "Por favor, introduza o seu nome de utilizador"
                            : "Please type your username"}
                        </span>
                      ))}
                    {alertFillUsernameForRecover && (
                      <span className="login-form-fields-error">
                        {isPT
                          ? "Por favor, introduza o seu nome de utilizador"
                          : "Please type your username"}
                      </span>
                    )}

                    {recoveryMode ? (
                      <div style={{ marginTop: 10 }}>
                        <label>
                          {isPT ? "Email de recuperação" : "Email"}
                          <input
                            placeholder={
                              isPT
                                ? "Introduza o seu endereço de email"
                                : "Type your email"
                            }
                            type="text"
                            id="recoverymail"
                            value={selectedRecoveryEmail}
                            onChange={handleChangeRecoveryEmail}
                          />
                        </label>
                        {selectedRecoveryEmail.trim().length === 0 && (
                          <span className="login-form-fields-error">
                            {isPT
                              ? "Por favor, introduza o seu email"
                              : "Please type your email"}
                          </span>
                        )}
                      </div>
                    ) : (
                      <div style={{ marginTop: 10 }}>
                        <label>
                          {isPT ? "Password" : "Password"}
                          <input
                            placeholder={
                              isPT
                                ? "Introduza a sua password"
                                : "Type your password"
                            }
                            type="password"
                            id="pw"
                            value={selectedPassword}
                            onChange={handleChangePassword}
                          />
                        </label>
                        {showMessage &&
                          (selectedPassword.length > 0 ? (
                            ""
                          ) : (
                            <span className="login-form-fields-error">
                              {isPT
                                ? "Por favor, introduza a sua password"
                                : "Please type your password"}
                            </span>
                          ))}
                      </div>
                    )}

                    {errorMessage !== null && (
                      <div className="error-message-info">{errorMessage}</div>
                    )}

                    <>
                      {recoveryMode ? (
                        <button
                          type="button"
                          disabled={selectedRecoveryEmail.trim().length === 0}
                          onClick={() => sendEmailForRecovery()}
                          className="send-email-for-recovery"
                        >
                          {isPT
                            ? "Enviar email para recuperação da palavra-passe"
                            : "Send email with instructions"}
                        </button>
                      ) : (
                        <button
                          type="submit"
                          className={`${
                            errorTest === false
                              ? "input-submit"
                              : "input-submit-block"
                          }`}
                          disabled={
                            errorTest === false
                              ? false
                              : loadingTest
                              ? true
                              : true
                          }
                          onClick={(e) => onFinish(e)}
                        >
                          {isPT ? "Iniciar sessão" : "Login"}
                        </button>
                      )}
                    </>
                    {emailRecoverError !== null && (
                      <div className="error-message-info">
                        {emailRecoverError}
                      </div>
                    )}
                    {sucessSendRecovery !== null && (
                      <div className="error-message-success">
                        {sucessSendRecovery}
                      </div>
                    )}
                    {!recoveryMode && (
                      <p
                        className="recover-password-button"
                        onClick={() =>
                          selectedUser.length > 0
                            ? (setRecoveryMode(!recoveryMode),
                              setAlertFillUsernameForRecover(false))
                            : setAlertFillUsernameForRecover(true)
                        }
                      >
                        {isPT
                          ? "Recuperação de palavra-passe"
                          : "Password Recovery"}
                      </p>
                    )}
                  </form>
                ) : (
                  <img
                    className="loading-gif"
                    alt="loading-gif"
                    src="images/spinning_loading.gif"
                  ></img>
                )}
              </>
            ) : (
              <img
                className="loading-gif"
                alt="loading-gif"
                src="images/spinning_loading.gif"
              ></img>
            )}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                minHeight: 110,
              }}
            >
              {loadingTest ? (
                <img
                  className="loading-gif"
                  alt="loading"
                  src="images/spinning_loading.gif"
                ></img>
              ) : (
                <>
                  <img
                    alt="host-logo"
                    className="img-host-logo"
                    src={logoHost}
                  ></img>

                  {testConnectionResult !== null && (
                    <>
                      {testConnectionResult === "erro" ? (
                        <>
                          {" "}
                          {errorTest && (
                            <span
                              className="login-form-fields-error"
                              style={{
                                marginTop: 20,
                                fontSize: 12,
                                padding: "5px 10px",
                              }}
                            >
                              {isPT
                                ? "Ocorreu um erro, ligação inválida"
                                : "An error occured, invalid connection"}
                            </span>
                          )}
                        </>
                      ) : (
                        <>
                          <span
                            style={{
                              marginTop: 10,
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              fontWeight: 500,
                              fontSize: 14,
                              flexDirection: "column",
                            }}
                          >
                            <div className="tooltip">
                              {" "}
                              {
                                testConnectionResult.PmsVersion.Version.split(
                                  "."
                                )[3]
                              }
                              <span className="tooltiptext">
                                {" "}
                                {
                                  testConnectionResult.PmsVersion
                                    .ServicePackInfo
                                }
                              </span>
                            </div>

                            <span
                              style={{
                                margin: "10px 0 5px 0",
                                fontWeight: 300,
                                padding: "5px 10px",
                                borderRadius: "5px",
                                background:
                                  currentDate !==
                                    testConnectionResult.HotelDateFixedFormat &&
                                  "#cdd4db",
                                color:
                                  currentDate !==
                                    testConnectionResult.HotelDateFixedFormat &&
                                  "white",
                              }}
                            >
                              {isPT ? ptDate : enDate}
                            </span>
                            {testConnectionResult.IsTrain && (
                              <span
                                style={{
                                  fontWeight: 300,
                                  color: "#5c89ff",
                                }}
                              >
                                {isPT
                                  ? "Modo treino ativo"
                                  : "Active training mode"}
                              </span>
                            )}
                          </span>

                          <div
                            style={{
                              fontSize: 9,
                              color: "#8894a1",
                              position: "absolute",
                              bottom: 10,
                              right: 10,
                            }}
                          >
                            {testConnectionResult.showDbInfo ? (
                              <span>{`${testConnectionResult.ServerAlias} ; ${testConnectionResult.DatabaseName} ${testConnectionResult.PlatformUid}`}</span>
                            ) : (
                              <span>{testConnectionResult.PlatformUid}</span>
                            )}
                          </div>
                        </>
                      )}
                    </>
                  )}
                </>
              )}
            </div>
          </div>
          <div className="wrapper-logos">
          <img
            className="host-logos"
            alt="host-logos"
            src={logos}
          ></img>
          </div>
        </div>
      )}
    </>
  );
}
